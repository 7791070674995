.pad_parent {
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  grid-template-rows: repeat(6, calc(100vh / 6));
  padding: 10px 0;

  .pad_container {
    grid-column: 4/10;
    grid-row: 2/5;
  }
  .sign_pad {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 20px;
  }
  .sign_made {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  
  }
  .sign_buttons{
    grid-row: 4/5;
    display:flex;
  }
  button {
   // grid-row: 2/3;
   // width: 90%;
   
    &.clear_sign {
      background-color: none;
      color: white;
    }
  }
  @media(max-width:800px){
    .pad_container{
      grid-column:1/13;
      height:250px;
      padding: 0 10px;
    }
  }
  @media(max-width:1200px){
    .pad_container{
      grid-column:1/13;
      grid-row:1/7;
      padding: 50px;
    }
  }
}
