.report {
  grid-column: 3/13;
  width: 100%;
  justify-self: end;
  grid-row: 3/4;
  padding: 0;
  .selectors {
    display: flex;
    flex-direction: column;
    .MuiFormControl-root {
      margin: 1rem 0;
    }
  }
}
