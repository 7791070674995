.login{
    grid-column: 1/13;
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    img{
        margin-bottom: 3rem;
        width: 252px;
        align-self: center;
    }
    .input_form{
        margin-bottom: 2rem;
        width: 300px;
        align-self: center;
    }
    button{
        align-self: center;
        width:220px;
        height:40px;
        font-size:0.9rem;
    }
    .css-cf7v2c-MuiPaper-root-MuiAlert-root{
        width:250px;
        align-self: center;
        position: absolute;
        top: 510px;
    }
}