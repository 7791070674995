.filter_box{
   // height: 200px;
    width:200px;
    background-color: white;
    position: absolute;
    top: 4rem;
    right:4rem;
    &.shape{
        padding:20px;
    margin:0

    }
    font-size:0.8rem;
    h4{
        margin:1em 0 0 0;
    }
    .searchbar{
        
        display:flex;
        flex-direction: column;
       
        
        span{
            font-size:0.7em;
            margin-bottom:1em;
        }
        .filter{
            border: none;
            align-self: end;
            font-size:1em;
            margin:0;
            padding:0;
            svg{
                width:0.7em;
            }
        }
    }
    .filter{
        margin-top: 1rem;
        align-self: center;
    }
    .sort{
        display:flex;
        justify-content: space-between;
        margin-top: 0.5em;
        font-size:0.9em;
        
    }
    
}