.delete_project {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    width: 25%;
    height: 40%;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
  @media(max-width:1200px){
    font-size:13px;
    justify-content: flex-end;
    &-content {
      width: 60%;
    height: 250px;

      margin-right: 10px;
  }
  }
}
