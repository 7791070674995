$color-1: #393939;
$color-2: lighten(#f77035, 0.8);

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap");
@import "./components/mybutton";
@import "./components/navbar";
@import "./components/topbar";
@import "./components/projectCard";
@import "./components/projectList";
@import "./components/employeesGrid";
@import "./components/signPad";
@import "./components/deleteProject";
@import "./components/filterBox";
@import "./components/reportByDate";
@import "./views/login";
@import "./views/project";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
ul {
  list-style: none;
}

#root {
  display: grid;
  grid-template-columns: repeat(12,1fr);
  grid-template-rows: 80px 250px auto;
  @media(min-width:500px){
    grid-template-rows: 80px 200px auto;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
 // max-height: 100vh;
  grid-column: 3/13;
  grid-row: 2/4;
 // overflow-y: hidden;
 h2{
  font-size:1.5em;
 }
 &_report{
  grid-column:4/14;
 }
 @media(max-width:1200px){
  font-size:14px;
  grid-column: 2/13;
  width:95%;
  justify-self:end;
  &_report{
    grid-column:3/14;
   }
  
 }

}
.create {
  display: flex;
  width: 50%;
  justify-self: center;
  padding-left:50px;
  flex-direction: column;
  grid-row: 3/4;
  grid-column: 3/13;
  font-size: 1rem;
  @media(max-width:1000px){
    width:80%;
    font-size:0.8rem;
  }
  &_project{
    margin: 0 auto;
  }
  &_complete{
   padding-bottom: 5rem;
  }
  h3,p{
    margin:0.5em;
  }
  .input_form {
    margin: 1em 0;
  }
  button {
    width: 100%;
  }
}
.hidden {
  visibility: hidden;
}
.ml-20 {
  margin-left: 20px;
}

.show_employees {
  grid-column: 3/13;
  width: 100%;
  justify-self: end;
  grid-row: 3/4;
  padding: 0;
}

.start {
  justify-self: start;
}

.mb-10{
  margin-bottom: 10px;
}
.mt-10{
  margin-top:20px;
}

.project_alert{
 grid-row:3/4;
 grid-column: 3/13;
 justify-self: center;
 align-self: end;
 margin: 1rem 0;
 position: relative;
 bottom: 20px;
 &.warning{
  position: relative;
  top: 4rem;
 }


}
.shape{
  padding: 40px 20px 60px 20px;
  box-shadow: -2px -1px 18px -7px rgba(87, 84, 87, 0.3);
  border-radius: 20px;
  margin-bottom: 2rem;
}
.locked{
  opacity: 0.5;
}
.delete_icon{
  z-index:5;
  cursor:pointer;
}

.check_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;
}
.check_project{
 padding: 10px 20px;
 margin: 4px 10px;
 display: flex;
 flex-wrap: wrap;
 flex-direction: column;
 align-items: center;
 text-align: center;
 justify-content: space-between;
 &--amount{
  flex-direction:row;
  align-items: center;
  h5.title{
    margin:0;
  }
  h3{
    font-size: 1em;
  }
 }
 .title{
  margin-top: 3px;
  margin-bottom:10px;
  
 }
}

.settings{
  display:flex;
  flex-direction: column;
}