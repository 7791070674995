nav {
  display: grid;
  padding: 0 10px;
  height: 100vh;
  width: 225px;
  grid-template-columns: 1fr;
  border-right: 5px solid rgba(128, 128, 128, 0.297);
  grid-row: 2/4;
  grid-column: 1/4;
  position: fixed;
  top: 80px;
  background-color: white;
  .nav_button {
    display: none;
    margin-left: 20px;
  }
  &.mini {
    grid-column: 1/2;
    width: 60px;
    z-index: 15;
    overflow: hidden;
    grid-template-rows: 50px auto;
    transition: all 0.3s ease;
   
    .nav_button {
      display: block;
      &.open{
        margin-top:40vh;
      }
    }
    .main_ul {
      display: none;
    }
    &.expanded {
      .main_ul {
        display: flex;
      }
      width: 220px;
    }
  }
  @media (max-width: 1000px) {
    width: 105px;
    font-size: 0.8rem;
    padding: 0;
    .main_ul {
      padding: 0 5px;
    }
  }
  .main_ul {
    display: flex;
    align-items: start;
    flex-direction: column;
    padding: 0 20px;
    li {
      font-weight: 600;
      margin: 0.5rem 0;
      display: flex;
      color: $color-1;
      align-items: center;
      .secondary_ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 0 0 20px;
        li {
          font-weight: 400;
          font-size: 0.9em;
        }
      }
      a {
        text-decoration: none;
        color: $color-1;
        display: flex;
        align-items: center;
      }
    }
  }
}
