.top_bar {
  height: 60px;
  grid-column: 1/13;
  grid-row:1/2;
  padding: 5px 0;
  display:flex;
  justify-content: space-between;
  position:fixed;
  width:100%;
  background-color: white;
  z-index: 10;
  img {
    height: 60px;
    align-self: center;
    margin-left: 20px;
    width:150px;
  }
  .right{
    display:flex;
    width: auto;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    h3{
      margin-right:0.7em;
    }
  }

  @media(max-width:800px){
    img{
      width:90px;
      height:40px;
    }
    .right{
      h3{
        font-size:0.9rem;
      }
    }
  }
}
