.data {
  display: grid;
  grid-template-rows: 60px auto;
  padding: 0 30px;
  .info {
    grid-column: 1/13;
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.5fr 1fr 1fr 1fr 0.5fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    padding: 5px 0;
    .header_title {
      font-size: 0.9rem;
      margin: 0;
    }
    &:nth-child(odd) {
      background-color: rgba(211, 209, 209, 0.08);
    }
    h3 {
      font-size: 0.9rem;
      font-weight: 400;
    }

    &_name {
      color: $color-1;
      font-weight: 500;
      font-size: 0.9rem;
      justify-self: start;
      padding-left: 5px;
      margin: 0;
    }
    &_status {
      color: white;
      font-weight: 600;
      font-size: 0.8rem;
      padding: 2px 5px;
      border-radius: 15px;
      &.started {
        background-color: rgb(231, 133, 133);
      }
      &.completed {
        background-color: rgb(104, 207, 104);
      }
    }
  }
  @media(max-width:1200px){
    padding:0 10px ;
    overflow-x: scroll;
    .info{
      width:1000px;
    }
  }
}
