.project_list {
  padding: 0 5%;
  position:relative;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header_buttons{
      display:flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .list {
    padding: 50px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #9ccdc012;
    min-height:80vh;
  }
}
