.project_card {
  background-color: white;
  height: 200px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding:  20px;
  box-shadow: -2px -1px 18px -7px rgba(87, 84, 87, 0.3);
  border-radius: 20px;
  margin: 0.5rem 0;
  h3,h2{
    margin:0;
  }
  button {
    align-self: center;
    width: 100%;
  }
  color: $color-1;
  .title {
    font-size: 1.2rem;
  }
  .number {
    font-size: 0.7rem;
    font-weight: 400;
  }
  .edit_section{
    display:flex;
    justify-content: space-between;
    width:100%;
    button{
      width:20%;
    }
  }
}
