button {
  height: 30px;
  border-radius: 20px;
  border: none;
  //border-color: none;
  color: white;
  font-weight: 400;
  width: 80px;
  @media(max-width:800px){
    font-size: 0.6rem;
  }
  cursor: pointer;
  &.action{
    background-color: $color-1;
    font-weight: 600;

  }
  &.logout {
    background-color: rgb(190, 73, 73);
  }
  &.edit{
    color:$color-1;
    font-weight:600;
  }
  &.add{
    width:150px;
    background-color: rgb(59, 138, 59);
    font-weight: 600;
  }
  &.filter{
    align-self: center;
    margin-right:10px;
    background-color: white;
    color:black;
    display:flex;
    align-items:center;
    justify-content: center;
   border: 0.5px solid black;
   
  }
  &:hover{
    opacity: 0.8;
  }

}
