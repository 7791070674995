.project_body {
  position: relative;
  height: 185px;
  grid-column: 3/13;
  width: 90%;
  justify-self: center;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-rows: 90% 20%;
  grid-template-rows: auto;

  @media (max-width: 1200px) {
    font-size: 14px;
    svg {
      height: 0.7em;
      width: 0.7em;
    }
  }
  .project_header {
    color: $color-1;
    grid-column: 1/5;
    grid-row: 1/2;
    .project_header-name {
      font-size: 1.5em;
      color: darken($color-1, 1.2);
      margin: 0;
    }
    h2 {
      font-size: 0.9em;
      display: flex;
      font-weight: 400;
      text-align: center;
      margin: 0;
    }
    &-date {
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        .date_picker {
          width: 150px;
        }
      }
      .dayswitch {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
          color: green;
        }
      }
      .date_picker {
        input {
          font-size: 0.7rem;

          padding: 10px;
        }
        svg {
          font-size: 0.7em;
        }
      }
    }
    span {
      margin: 0 3rem 0 1rem;
      font-weight: 300;
    }
  }
  .employee {
    grid-column: 4/5;
    justify-self: end;
    @media (max-width: 1200px) {
      margin-right: 1rem;
    }
  }
  .dl {
    grid-column: 1/2;
    justify-self: start;
    color: $color-1;
    .MuiSvgIcon-root {
      cursor: pointer;
      margin: 0 15px 0 0;
    }
  }
  .hours {
    display: flex;
    grid-column: 2/4;
    align-self: start;
    justify-self: end;

    h4 {
      margin: 0;
      margin-right: 1rem;
      font-weight: 400;
      font-size: 0.9rem;

      span {
        font-weight: 700;
      }
    }
    @media (max-width: 500px) {
      grid-column: 2/13;
      justify-self: start;
      padding: 10px 0;
      h4 {
        font-size: 0.7rem;
      }
    }
  }
  @media (max-width: 1200px) {
    grid-column: 2/13;
    width: 90%;
    justify-self: end;
    .dl {
      grid-column: 1/4;
    }
  }
}
.pdf {
  position: absolute;
  height: 100vh;
  width: 80vw;
  background-color: white;
  margin: 0 10vw;
  border: 1px solid gray;

  .pdf_content {
    height: 80%;
    overflow-y: scroll;
    padding: 30px;

    .data {
      display: flex;
      flex-direction: column;
      .pdf_header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .pdf_date {
          justify-self: end;
        }
        h4 {
          margin: 5px 0;
          font-weight: 400;
        }
        margin-bottom: 1rem;
      }
      .info {
        grid-template-columns: repeat(8, 1fr);
        .sign {
          width: 80%;
          height: 30px;
          padding: 5px;
          justify-self: center;
        }
      }
    }
  }
  .buttons_pdf {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      margin: 0 10px;
    }
  }
}
